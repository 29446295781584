import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faLightbulb, faUser, faGlobeEurope,
  faFileAlt, faUserCircle, faInbox,
  faCalendar, faUsers, faTag,
  faMapPin, faCalendarAlt, faWrench,
  faExclamationCircle, faEnvelope, faRepeat,
  faArrowRightArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF
} from '@fortawesome/free-brands-svg-icons'

library.add(
  faLightbulb, faUser, faGlobeEurope,
  faFileAlt, faUserCircle, faInbox,
  faCalendar, faUsers, faTag,
  faMapPin, faCalendarAlt, faWrench,
  faExclamationCircle, faEnvelope, faRepeat,
  faArrowRightArrowLeft,
  faFacebookF
)

config.mutateApproach = 'sync'
dom.watch()
