import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['monthly', 'yearly', 'monthButton', 'yearButton']

  connect() {
  }

  showMonthly() {
    this.monthButtonTarget.classList.add('bg-white')
    this.monthButtonTarget.classList.add('shadow-sm')
    this.yearButtonTarget.classList.remove('bg-white')
    this.yearButtonTarget.classList.remove('shadow-sm')

    this.yearlyTargets.forEach((el) => {
      el.classList.add('hidden')
    })
    this.monthlyTargets.forEach((el) => {
      el.classList.remove('hidden')
    })
  }

  showYearly() {
    this.yearButtonTarget.classList.add('bg-white')
    this.yearButtonTarget.classList.add('shadow-sm')
    this.monthButtonTarget.classList.remove('bg-white')
    this.monthButtonTarget.classList.remove('shadow-sm')

    this.yearlyTargets.forEach((el) => {
      el.classList.remove('hidden')
    })
    this.monthlyTargets.forEach((el) => {
      el.classList.add('hidden')
    })
  }
}
