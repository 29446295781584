import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    var s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = this.data.get('embed')
    s.classList.add(`trial-form-js`)

    var x = document.getElementsByTagName('script')[0]
    x.parentNode.insertBefore(s,x)

    this.prepareTrialForm()
  }

  disconnect() {
    document.querySelector('script.trial-form-js').remove()
  }

  prepareTrialForm() {
    var controller = this
    // Check if .takashi exists
    if (document.querySelector(`.takashi[data-takashi-id='${this.element.dataset.takashiForm}']`)!=null) {
      this.element.setAttribute('disabled', false)
      this.element.innerHTML = this.data.get('ready')
    } else {
      // Wait 50ms if not
      this.element.setAttribute('disabled', true)
      setTimeout(function() {
        controller.prepareTrialForm()
      }, 100)
    }
  }
}
